import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { useNavigate, useSearchParams } from "react-router-dom";
import PatchEducationLogo from "../../../assets/patch-logo-white.png";
import { useMutation } from "@tanstack/react-query";
import { updateUserPassword } from "../../../api/auth.api";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { useUserAuth } from "@/contexts/authentication/UserAuthContext";
import { setAllTokens } from "@/api/utils";

const { VITE_REACT_APP_ENVIRONMENT } = import.meta.env;

// Define the Zod schema
const schema = z
  .object({
    password: z.string().min(8, "Password must be at least 8 characters long"),
    password2: z.string(),
  })
  .refine((data) => data.password === data.password2, {
    message: "Passwords must match",
    path: ["password2"],
  });

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { toast } = useToast();
  useEffect(() => {
    if (!token) {
      navigate("/login");
      toast({
        title: "No token, redirected to login",
      });
    }
  }, [token]);

  const { currentUser, fetchUser } = useUserAuth();

  const queryParams = new URLSearchParams(window.location.search);
  // To show the UI for setting a new password, rather than forgot
  const isSetPassword = queryParams.get("type") === "set";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const navigate = useNavigate();

  const updatePassword = useMutation({
    mutationKey: ["updatePassword"],
    mutationFn: updateUserPassword,
    onSuccess: async (data) => {
      console.log(data);

      setAllTokens({
        authToken: data.auth_token,
        client: data.client,
        uid: data.uid,
        id: data.id,
        expiry: data.expiry,
        tokenType: data.token_type,
      });

      toast({
        title: "Password Updated",
        description: isSetPassword
          ? "Your password has been set successfully. You can now log in"
          : "Your password has been updated successfully. You can now log in",
        variant: "default",
      });

      await fetchUser().then(() => {
        navigate("/records");
      });
    },
    onError: (error) => {
      console.error("An error occurred:", error);
    },
  });

  useEffect(() => {
    if (currentUser) {
      navigate("/records");
    }
  }, [currentUser]);

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = async (formData) => {
    return updatePassword.mutateAsync({
      password: formData.password,
      token: token as string,
    });
  };

  return (
    <div className="h-screen w-full bg-slate-800">
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-16 w-auto"
            src={PatchEducationLogo}
            alt="Patch Education"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-slate-100">
            {isSetPassword ? "Set Password" : "Forgot Password"}
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-transparent md:bg-white  px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-white md:text-gray-900"
                >
                  New Password
                </label>
                <div className="mt-2">
                  <Input
                    {...register("password")}
                    id="password"
                    name="password"
                    type="password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6"
                  />
                  {errors.password && (
                    <p className="text-red-500 text-sm">
                      {errors.password.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password2"
                  className="block text-sm font-medium leading-6  text-white md:text-gray-900"
                >
                  Confirm Password
                </label>
                <div className="mt-2">
                  <Input
                    {...register("password2")}
                    id="password2"
                    name="password2"
                    type="password"
                  />
                  {errors.password2 && (
                    <p className="text-red-500 text-sm">
                      {errors.password2.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FF3F44]"
                >
                  {isSetPassword ? "Set Password" : "Reset Password"}
                </button>
              </div>

              {updatePassword.error && (
                <div className="w-full flex flex-col items-center">
                  <p className="text-red-500 text-sm">
                    Invalid Reset Password Link
                  </p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
