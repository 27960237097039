import { apiFetch } from "./utils";

const url = import.meta.env.VITE_REACT_APP_API_URL + "/v1/accounts";

export class AccountApi {
  static getAccount({ id }: { id: string }) {
    return apiFetch(`${url}/${id}`, {
      method: "GET",
    });
  }
}
