import { RestUser } from "@/pages/admin/types";

import { apiFetch } from "./utils";

const url = import.meta.env.VITE_REACT_APP_API_URL + "/v1/users";

export class UserApi {
  static updateUser({
    id,
    data,
  }: {
    id: string;
    data: {
      user: Pick<
        RestUser,
        | "email"
        | "first_name"
        | "last_name"
        | "kind"
        | "phone"
        | "has_analytics"
        | "hospital_ids"
      >;
    };
  }) {
    return apiFetch(`${url}/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }
}
