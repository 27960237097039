// src/contexts/UserAuthContext.tsx

import { useQuery } from "@apollo/react-hooks";
import React, { createContext, useCallback, useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { GET_CURRENT_USER_QUERY } from "./queries";

import { useToast } from "@/hooks/use-toast";
import { GraphqlUser } from "@/pages/admin/types";
import { useMutation } from "@tanstack/react-query";
import {
  getCurrentUser,
  logUserOut as logUserOutApi,
} from "../../api/auth.api";
import { clearAllTokens } from "../../api/utils";
import BackgroundScreen from "../../components/BackgroundScreen";
import Spinner from "../../components/Spinner";
import {
  removeLocalStorageEntry,
  setLocalStorageEntry,
} from "../../utils/local-storage";

export const DOCTOR_USER_LOCALSTORAGE_KEY = "_transcription_doctor_user";

interface UserAuthContextType {
  currentUser: GraphqlUser | null;
  logUserIn: (user: GraphqlUser) => void;
  logUserOut: () => Promise<void>;
  doctorLoading: boolean;
  fetchUser: () => Promise<unknown>;
}

const UserAuthContext = createContext<UserAuthContextType | undefined>(
  undefined,
);

export const UserAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { toast } = useToast();
  const [currentUser, setCurrentUser] = useState<GraphqlUser | null>(null);

  const { loading: doctorLoading } = useQuery(GET_CURRENT_USER_QUERY, {
    onCompleted: (data) => {
      setCurrentUser(data?.currentUser);
    },
  });

  const logUserIn = useCallback((user: GraphqlUser) => {
    setLocalStorageEntry(DOCTOR_USER_LOCALSTORAGE_KEY, {
      authToken: user.authToken,
      id: user.id,
    });

    setCurrentUser(user);
  }, []);

  const logoutUserMutation = useMutation({
    mutationFn: async () => {
      try {
        await logUserOutApi();
      } catch (error) {
        console.log(error);
      } finally {
        clearAllTokens();
        removeLocalStorageEntry(DOCTOR_USER_LOCALSTORAGE_KEY);
        setCurrentUser(null);
      }
    },
    onSettled: () => {
      toast({
        title: "Logged out",
        variant: "default",
      });
    },
  });

  const fetchUser = async () => {
    try {
      const { data: user } = await getCurrentUser();

      if (!user) {
        return;
      }

      logUserIn(user);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const logUserOut = () => logoutUserMutation.mutateAsync();

  return (
    <UserAuthContext.Provider
      value={{
        fetchUser,
        currentUser,
        logUserIn,
        logUserOut,
        doctorLoading,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

export const useUserAuth = () => {
  const context = useContext(UserAuthContext);
  if (context === undefined) {
    throw new Error("UserAuthProvider is missing");
  }
  return context;
};

export const UserAuthenticatedRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { currentUser, doctorLoading } = useUserAuth();

  if (!doctorLoading && !currentUser) {
    // User is not authenticated
    return <Navigate to="/login" />;
  }

  if (doctorLoading) {
    return (
      <BackgroundScreen>
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div className="h-[120px]">
              <div className="pt-10" />
              <Spinner size="medium" className="text-white" />
            </div>
          </div>
        </div>
      </BackgroundScreen>
    );
  }

  return <>{children}</>;
};

export const AnalyticsAuthenticatedRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { currentUser, doctorLoading } = useUserAuth();

  if (!doctorLoading && !currentUser) {
    return <Navigate to="/login" />;
  }

  if (doctorLoading) {
    return null;
  }

  if (currentUser?.hasAnalytics) {
    return <>{children}</>;
  } else {
    return <Navigate to="/login" />;
  }
};
