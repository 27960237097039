import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { getLocalStorageEntry } from "../utils/local-storage";
import { onError } from "@apollo/client/link/error";
import { getAllTokens } from "../api/utils";

import { HOSPITAL_LOCALSTORAGE_KEY } from "@/contexts/hospital";
import { ADMIN_USER_LOCALSTORAGE_KEY } from "@/contexts/authentication/AdminAuthContext";
import { DOCTOR_USER_LOCALSTORAGE_KEY } from "@/contexts/authentication/UserAuthContext";
const { VITE_REACT_APP_GRAPHQL_URL } = import.meta.env;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  const adminUser = getLocalStorageEntry(ADMIN_USER_LOCALSTORAGE_KEY);
  const doctorUser = getLocalStorageEntry(DOCTOR_USER_LOCALSTORAGE_KEY);
  const hospital = getLocalStorageEntry(HOSPITAL_LOCALSTORAGE_KEY);

  const authToken = getAllTokens();

  const initialHeaders = {
    "X-ADMIN-AUTH-TOKEN": adminUser?.authToken,
    "X-USER-AUTH-TOKEN": doctorUser?.authToken,
    "X-Hospital-ID": hospital?.id,
    "access-token": authToken.authToken,
    client: authToken.client,
    uid: authToken.uid,
    tokenType: authToken.tokenType,
    expiry: authToken.expiry,
  };

  return {
    headers: {
      ...headers,
      ...initialHeaders,
    },
  };
});

const httpLink = new HttpLink({
  uri: VITE_REACT_APP_GRAPHQL_URL,
  credentials: "omit",
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
