export interface RestAccount {
  id: string;
  hospitals: RestHospital[];
  users: RestUser[];
}

export interface RestUser {
  id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  phone: string;
  password: string;
  kind: UserKind;
  email: string;
  email_signature: string;
  hospital_count: number;
  has_analytics: boolean;
  authToken: string;
  username: string;
  photo_url: string;
  hospitals: Hospital[];
  __typename: "User";
}

export interface RestHospital {
  id: string;
  name: string;
  full_address: string;
  timezone: string;
  doctor_count: number;
  website: string;
  user_count: number;
}

export interface Hospital {
  id: string;
  name: string;
  fullAddress: string;
  timezone: string;
  doctorCount: number;
  website: string;
  userCount: number;
  integrationEnabled?: boolean;
}

export interface AdminAccount {
  id: string;
  name: string;
  userCount: number;
  doctorCount: number;
  hospitalCount: number;
  mrr: number;
  licenseCount: number;
  hardwareCount: number;
  status: "trial" | "active" | "inactive" | "cancelled"; // Add other possible statuses if needed
  friendlyCustomerSince: string | null;
  friendlyCreatedAt: string;
  stripeSubscriptionId: string | null;
  stripeCheckoutUrl: string | null;
  stripeSubscriptionUrl: string | null;
  hospitals: Hospital[];
  users: GraphqlUser[];
  __typename: "Account";
}

export enum UserKind {
  Manager = "manager",
  Doctor = "doctor",
  Nurse = "nurse",
  Other = "other",
}

/**
 * Has snake_case types to match rest endpoints
 */
export interface RestUser {
  id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  phone: string;
  password: string;
  kind: UserKind;
  email: string;
  email_signature: string;
  hospital_count: number;
  has_analytics: boolean;
  auth_token: string;
  username: string;
  photo_url: string;
  hospital_ids: string[];
}

/**
 * Has camelCase types
 */
export interface GraphqlUser {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phone: string;
  password: string;
  kind: UserKind;
  email: string;
  emailSignature: string;
  hospitalCount: number;
  hasAnalytics: boolean;
  authToken: string;
  username: string;
  photoUrl: string;
  hospitals: Hospital[];
  __typename: "User";
}

export interface Appointment {
  id: string;
  scheduledAt: string; // ISO8601 DateTime
  status: string;
  kind: string;
  friendlyDate: string;
  friendlyTime: string;
  patientName: string;
  hospital: Hospital;
  patient: Patient;
}

export interface Patient {
  id: string;
  name: string;
  species: string;
  breed: string;
  birthdate: string; // ISO8601 Date
  age: number;

  vetdata_id?: string;
  vetdata_pms_id?: string;
  vetdata_name?: string;
  vetdata_species_code?: string;
  vetdata_species_description?: string;
  vetdata_species_breed_code?: string;
  vetdata_species_breed_description?: string;
  vetdata_species_color_code?: string;
  vetdata_species_color_description?: string;
  vetdata_species_gender_code?: string;
  vetdata_species_gender_description?: string;
  vetdata_date_of_birth?: string; // ISO8601 Date
  friendly_vetdata_date_of_birth?: string;
  vetdata_age?: number;
  friendly_vetdata_date_of_death?: string;
  vetdata_date_of_death?: string; // ISO8601 Date
  vetdata_deleted?: boolean;
  vetdata_is_deceased?: boolean;
  vetdata_inactive?: boolean;
  vetdata_current_weight?: number;
  vetdata_current_weight_unit?: string;
  vetdata_last_transaction_date?: string; // ISO8601 Date
  vetdata_site_id?: string;
  vetdata_suspend_reminders?: boolean;
  vetdata_created_at?: string; // ISO8601 Date
  vetdata_updated_at?: string; // ISO8601 Date
  vetdata_removed_at?: string; // ISO8601 Date
}
