import { Popover } from "@headlessui/react";
import clsx from "clsx";
import HeaderDesktop from "./HeaderDesktop";

const Header = () => {
  // {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */ }
  return (
    <Popover
      as="header"
      className={({ open }) =>
        clsx(
          {
            "fixed inset-0 z-40 overflow-y-auto": open,
          },
          "bg-white lg:static lg:overflow-y-visible",
        )
      }
    >
      {({ open }) => (
        <>
          <HeaderDesktop open={open} />
        </>
      )}
    </Popover>
  );
};

export default Header;
