// src/UserRoutes.tsx

import { Navigate, Route, Routes } from "react-router-dom";
import {
  AnalyticsAuthenticatedRoute,
  UserAuthenticatedRoute,
} from "../contexts/authentication/UserAuthContext";
import HospitalProvider from "../contexts/hospital";

import DoctorEmailInboxPage from "../pages/doctor/email-inbox";
import DoctorEmailThreadPage from "../pages/doctor/email-thread";
import DoctorTelemedicineCallPage from "../pages/doctor/telemedicine-call";
import DoctorTelemedicineCallsPage from "../pages/doctor/telemedicine-calls";
import DoctorTranscriptionPage from "../pages/doctor/transcription";
import DoctorTranscriptionsPage from "../pages/doctor/transcriptions";
import VideoPage from "../pages/doctor/video";
import Insights from "../pages/insights";
import UserSignOut from "@/pages/doctor/auth/UserSignOut";
import UserLoginPage from "@/pages/doctor/login";
import DoctorForgotPasswordPage from "../pages/doctor/forgot-password/ForgotPassword";
import DoctorResetPasswordPage from "../pages/doctor/reset-password";

const UserRoutes = () => {
  return (
    <Routes>
      <Route path="auth/sign_out" element={<UserSignOut />} />
      <Route path="/login" element={<UserLoginPage />} />
      <Route path="email" element={<Navigate to="/email/inbox" />} />
      <Route
        path="email/inbox"
        element={
          <UserAuthenticatedRoute>
            <HospitalProvider>
              <DoctorEmailInboxPage filter="inbox" />
            </HospitalProvider>
          </UserAuthenticatedRoute>
        }
      />
      {/* ... other email routes ... */}
      <Route
        path="email/:emailThreadId"
        element={
          <UserAuthenticatedRoute>
            <HospitalProvider>
              <DoctorEmailThreadPage />
            </HospitalProvider>
          </UserAuthenticatedRoute>
        }
      />

      {/* Records (Transcriptions) Routes */}
      <Route
        path="records/:transcriptionId"
        element={
          <UserAuthenticatedRoute>
            <HospitalProvider>
              <DoctorTranscriptionPage />
            </HospitalProvider>
          </UserAuthenticatedRoute>
        }
      />
      <Route
        path="records"
        element={
          <UserAuthenticatedRoute>
            <HospitalProvider>
              <DoctorTranscriptionsPage />
            </HospitalProvider>
          </UserAuthenticatedRoute>
        }
      />

      {/* Telemedicine Routes */}
      <Route
        path="telemedicine/:callId"
        element={
          <UserAuthenticatedRoute>
            <HospitalProvider>
              <DoctorTelemedicineCallPage />
            </HospitalProvider>
          </UserAuthenticatedRoute>
        }
      />
      <Route
        path="telemedicine"
        element={
          <UserAuthenticatedRoute>
            <HospitalProvider>
              <DoctorTelemedicineCallsPage />
            </HospitalProvider>
          </UserAuthenticatedRoute>
        }
      />
      <Route
        path="video/:token"
        element={
          <UserAuthenticatedRoute>
            <HospitalProvider>
              <VideoPage />
            </HospitalProvider>
          </UserAuthenticatedRoute>
        }
      />

      {/* Insights Route */}
      <Route
        path="insights"
        element={
          <AnalyticsAuthenticatedRoute>
            <Insights />
          </AnalyticsAuthenticatedRoute>
        }
      />

      <Route path="/forgot-password" element={<DoctorForgotPasswordPage />} />
      <Route path="/reset-password" element={<DoctorResetPasswordPage />} />

      {/* Catch-all User Route */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default UserRoutes;
