import BackgroundScreen from "@/components/BackgroundScreen";
import Spinner from "@/components/Spinner";
import { useUserAuth } from "@/contexts/authentication/UserAuthContext";

import React from "react";
import { useNavigate } from "react-router-dom";

export default function UserSignOut() {
  const navigate = useNavigate();
  const { logUserOut } = useUserAuth();

  const handleLogout = async () => {
    await logUserOut();

    navigate("/login");
  };

  React.useEffect(() => {
    handleLogout();
  }, []);

  return (
    <BackgroundScreen>
      <Spinner />
    </BackgroundScreen>
  );
}
