import React from "react";

interface Props {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onBlur?: () => void;
  onKeyDown?: (evt: React.KeyboardEvent<HTMLDivElement>) => void;
  children?: React.ReactNode;
  className?: string;
  innerRef?: (div: HTMLDivElement) => void;
  "data-testid"?: string;
  tabIndex?: number;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export default function Clickable(props: Props) {
  return (
    <div
      data-testid={props["data-testid"]}
      onClick={(e) => {
        if (props.disabled) {
          return;
        }
        return props.onClick?.(e);
      }}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      role="button"
      tabIndex={props.tabIndex ?? 0}
      onKeyDown={(evt) => {
        if (evt.key === "Enter" && props.onClick) {
          // @ts-ignore
          props.onClick(evt);
        }
        if (props.onKeyDown) {
          props.onKeyDown(evt);
        }
      }}
      className={props.className}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      style={{
        cursor: props.disabled ? "not-allowed" : "pointer",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
}
