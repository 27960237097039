import { ApolloProvider } from "@apollo/client";
import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import CheckoutFailurePage from "./pages/checkout_failure";
import CheckoutSuccessPage from "./pages/checkout_success";

import * as Sentry from "@sentry/react";
import client from "./config/apollo";

// https://blog.saeloun.com/2022/02/17/how-to-integrate-react-app-with-google-analytics/

import ReactGA from "react-ga";
import { Toaster } from "./components/ui/toaster";
import { AdminAuthProvider } from "./contexts/authentication/AdminAuthContext";
import { UserAuthProvider } from "./contexts/authentication/UserAuthContext";
import AdminRoutes from "./routes/AdminRoutes";
import UserRoutes from "./routes/UserRoutes";
const TRACKING_ID = "G-9NHE495S13"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const { VITE_REACT_APP_ENVIRONMENT } = import.meta.env;

const queryClient = new QueryClient();

Sentry.init({
  environment: VITE_REACT_APP_ENVIRONMENT,
  dsn: "https://2eb3c598cea909882dd1b711375d4b41@o4506543658827776.ingest.sentry.io/4506543678291968",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/transcription-frontend\.onrender\.com\//,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: VITE_REACT_APP_ENVIRONMENT == "production",
});

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <BrowserRouter>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Navigate to="/login" />} />

            <Route path="/checkout_success" element={<CheckoutSuccessPage />} />
            <Route path="/checkout_failure" element={<CheckoutFailurePage />} />

            {/* Admin Routes */}
            <Route
              path="/admin/*"
              element={
                <AdminAuthProvider>
                  <AdminRoutes />
                </AdminAuthProvider>
              }
            />

            {/* User (Doctor) Routes */}
            <Route
              path="/*"
              element={
                <UserAuthProvider>
                  <UserRoutes />
                </UserAuthProvider>
              }
            />

            {/* Catch-all Route */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

export default App;
