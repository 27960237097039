import BackgroundScreen from "@/components/BackgroundScreen";
import Spinner from "@/components/Spinner";
import { useAdminAuth } from "@/contexts/authentication/AdminAuthContext";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function AdminSignOut() {
  const navigate = useNavigate();
  const { logAdminUserOut } = useAdminAuth();

  const handleLogouts = async () => {
    await logAdminUserOut();

    navigate("/admin/login");
  };

  React.useEffect(() => {
    handleLogouts();
  }, []);

  return (
    <BackgroundScreen>
      <Spinner />
    </BackgroundScreen>
  );
}
