import MainLayout from "@/components/doctor/main-layout";
import Content from "./content";
import EmailLeftBar from "@/components/doctor/email-left-bar";

const EmailInboxPage = ({ filter }: { filter: string }) => {
  return (
    <MainLayout>
      <div
        className="flex flex-row h-100 w-full"
        style={{ height: "calc(100vh - 4rem)" }}
      >
        <EmailLeftBar />
        <Content filter={filter} />
      </div>
    </MainLayout>
  );
};

export default EmailInboxPage;
