import StatusBadge from "@/components/admin-account-status-badge";
import MainLayout from "@/components/admin/main-layout";

import { AccountApi } from "@/api/account.api";
import { useQuery } from "@tanstack/react-query";
import { Link, Navigate, NavLink, useParams } from "react-router-dom";
import BillingTab from "./billing-tab";
import HospitalsTab from "./hospitals-tab";
import UsersTab from "./users-tab/UsersTab";

const AdminAccountPage = () => {
  const { accountId, tab: _tab } = useParams();

  if (!accountId) {
    return <Navigate to="/admin/accounts" />;
  }

  const { data, refetch } = useQuery({
    queryFn: () => AccountApi.getAccount({ id: accountId! }),
    queryKey: ["account", accountId],
  });

  if (!data) {
    return null;
  }

  const tab = _tab || "hospitals";

  return (
    <MainLayout>
      <div className="p-8">
        <Link
          to="/admin/accounts"
          className="text-xs text-indigo-500 hover:text-indigo-600"
        >
          &larr; Back
        </Link>
        <div className="flex flex-row items-end justify-start">
          <h1 className="text-xl font-bold mr-4">{data.name}</h1>
          <div>
            <StatusBadge status={data.status} />
          </div>
        </div>
        <h3 className="text-medium font-medium text-slate-500">
          {data.friendlyCustomerSince ? (
            <span>Customer Since {data.friendlyCustomerSince}</span>
          ) : (
            <span>Created {data.friendlyCreatedAt}</span>
          )}
        </h3>
        <br />
        <p className="text-slate-600">
          <span className="font-medium text-slate-800">MRR</span>: ${data.mrr}
        </p>
        <br />
        <div>
          <div className="sm:block">
            <div className="border-b border-slate-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                <NavLink
                  to={`/admin/accounts/${accountId}/hospitals`}
                  className={({ isActive }) =>
                    isActive
                      ? "border-indigo-500 text-indigo-600 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                      : "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                  }
                >
                  Hospitals
                </NavLink>
                <NavLink
                  to={`/admin/accounts/${accountId}/users`}
                  className={({ isActive }) =>
                    isActive
                      ? "border-indigo-500 text-indigo-600 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                      : "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                  }
                >
                  Users
                </NavLink>
                <NavLink
                  to={`/admin/accounts/${accountId}/billing`}
                  className={({ isActive }) =>
                    isActive
                      ? "border-indigo-500 text-indigo-600 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                      : "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                  }
                >
                  Billing
                </NavLink>
              </nav>
            </div>
          </div>
        </div>
        <div className="py-8">
          {tab === "hospitals" && (
            <HospitalsTab refetch={refetch} account={data} />
          )}
          {tab === "users" && <UsersTab refetch={refetch} account={data} />}
          {tab === "billing" && <BillingTab refetch={refetch} account={data} />}
        </div>
      </div>
    </MainLayout>
  );
};

export default AdminAccountPage;
