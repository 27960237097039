import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { LOGIN_ADMIN_USER_MUTATION } from "./queries";

import PatchIconLogoWhite from "@/assets/patch-icon-logo-white.png";

import { setAllTokens } from "../../../api/utils";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { useAdminAuth } from "@/contexts/authentication/AdminAuthContext";
import { useNavigate } from "react-router-dom";
import Clickable from "@/components/Clickable";

function AdminLoginPage() {
  const { logAdminUserIn, currentAdminUser, adminLoading } = useAdminAuth();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    if (adminLoading) {
      return;
    }
    if (currentAdminUser) {
      navigate("/admin/records");
    }
  }, [currentAdminUser, adminLoading]);

  const [loginAdminUser, _] = useMutation(LOGIN_ADMIN_USER_MUTATION, {
    errorPolicy: "all",
    onError: (e) => {
      console.log(e);
      setError(true);
    },
    onCompleted: (data) => {
      setError(false);
      logAdminUserIn(data.loginAdminUser);

      const { authToken, client, id, uid, expiry, tokenType } =
        data.loginAdminUser;

      // Store tokens in localStorage
      setAllTokens({
        authToken,
        client,
        id,
        expiry,
        tokenType,
        uid,
      });

      navigate("/admin/records");
    },
    variables: {
      email,
      password,
      rememberMe,
    },
  });

  const onSubmit = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    loginAdminUser();
  };

  return (
    <div className="h-screen w-full bg-slate-800">
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Clickable
            onClick={() => {
              navigate("/login");
            }}
          >
            <img
              className="mx-auto h-16 w-auto"
              src={PatchIconLogoWhite}
              alt="Patch Education"
            />
          </Clickable>
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-slate-100">
            Admin Login
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Checkbox
                    value={String(rememberMe)}
                    onChange={() => setRememberMe((prev) => !prev)}
                    id="remember-me"
                    name="remember-me"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-3 block text-sm leading-6 text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                {/* <div className="text-sm leading-6">
                  <a href="#" className="font-semibold text-blue-400 hover:opacity-80">Forgot password?</a>
                </div> */}
              </div>

              <div>
                <button
                  onClick={onSubmit}
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FF3F44]"
                >
                  Sign in
                </button>
              </div>
              {error && (
                <div className="w-full flex flex-col items-center">
                  <p className="text-red-500 text-sm">
                    Incorrect email or password
                  </p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLoginPage;
