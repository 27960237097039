import { Navigate, Route, Routes } from "react-router-dom";

import AdminLoginPage from "@/pages/admin/LoginPage";
import AdminAccountPage from "../pages/admin/account";
import AdminAccountsPage from "../pages/admin/accounts";
import AdminSignOut from "../pages/admin/auth/AdminSignOut";
import AdminTranscriptionPage from "../pages/admin/transcription";
import AdminTranscriptionsPage from "../pages/admin/transcriptions";
import { AdminAuthenticatedRoute } from "./AdminAuthenticatedRoute";

function AdminRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<AdminLoginPage />} />
      <Route
        path="records/:transcriptionId"
        element={
          <AdminAuthenticatedRoute>
            <AdminTranscriptionPage />
          </AdminAuthenticatedRoute>
        }
      />
      <Route
        path="records/all"
        element={
          <AdminAuthenticatedRoute>
            <AdminTranscriptionsPage filter="all" />
          </AdminAuthenticatedRoute>
        }
      />
      <Route
        path="records"
        element={
          <AdminAuthenticatedRoute>
            <AdminTranscriptionsPage filter="proofreading" />
          </AdminAuthenticatedRoute>
        }
      />
      <Route
        // Optional tab parameter
        path="accounts/:accountId/:tab?"
        element={
          <AdminAuthenticatedRoute>
            <AdminAccountPage />
          </AdminAuthenticatedRoute>
        }
      />

      <Route
        path="accounts"
        element={
          <AdminAuthenticatedRoute>
            <AdminAccountsPage />
          </AdminAuthenticatedRoute>
        }
      />
      <Route path="auth/sign_out" element={<AdminSignOut />} />
      <Route path="*" element={<Navigate to="/admin/records" />} />
    </Routes>
  );
}

export default AdminRoutes;
