import { useEffect, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import PatchEducationLogo from "../../../assets/patch-logo-white.png";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { resetUserPassword } from "../../../api/auth.api";
import { Input } from "@/components/ui/input";
import { useUserAuth } from "@/contexts/authentication/UserAuthContext";
import Spinner from "@/components/Spinner";

const { VITE_REACT_APP_PRODUCTION_SERVER_URL } = import.meta.env;

// Define Zod schema for validation
const schema = z.object({
  email: z.string().email("Please enter a valid email address"),
});

const ForgotPasswordPage = () => {
  const { currentUser } = useUserAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const forgotPassword = useMutation({
    mutationKey: ["forgotPassword"],
    mutationFn: resetUserPassword,
    onMutate: () => {
      setSuccessMessage("");
      setError("");
    },
    onSuccess: () => {
      reset();
      setSuccessMessage("A password reset link has been sent to your email.");
    },
    onError: () => {
      setError("An error occurred. Please try again.");
    },
  });

  useEffect(() => {
    if (currentUser) {
      navigate("/course");
    }
  }, [currentUser]);

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = (formData) => {
    forgotPassword.mutate(formData);
  };

  return (
    <div className="h-screen w-full bg-slate-800">
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-16 w-auto"
            src={PatchEducationLogo}
            alt="Patch Education"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-slate-100">
            Forgot Password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  {!successMessage && (
                    <Input
                      {...register("email")}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                    />
                  )}
                  {errors.email && (
                    <p className="text-red-500 text-sm">
                      {errors.email.message}
                    </p>
                  )}
                </div>
              </div>

              {!successMessage && (
                <div>
                  <button
                    disabled={forgotPassword.isPending}
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FF3F44]"
                  >
                    {forgotPassword.isPending ? <Spinner /> : "Submit"}
                  </button>
                </div>
              )}

              {successMessage && (
                <div className="w-full flex flex-col items-center">
                  <p className="text-sm">{successMessage}</p>
                </div>
              )}
              {error && (
                <div className="w-full flex flex-col items-center">
                  <p className="text-sm">{error}</p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
