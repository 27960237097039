/**
 * Implements devise token auth endpoints
 */

import { GraphqlUser } from "@/pages/admin/types";
import { authHeaders } from "./utils";

const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL + "/auth";

export async function resetUserPassword({ email }: { email: string }) {
  return fetch(`${BASE_URL}/password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeaders(),
    },
    body: JSON.stringify({
      email,
      redirect_url: import.meta.env.VITE_REACT_APP_URL + "/reset-password",
    }),
  })
    .then((response) => {
      // Check if the response is ok (status 200-299)
      if (!response.ok) {
        return response.json().then((err) => {
          // Throw an error with the message from the API
          throw new Error(err.errors ? err.errors.join(", ") : "Unknown error");
        });
      }
      // Return the JSON response if successful
      return response.json();
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
}

export async function updateUserPassword({
  password,
  token,
}: {
  password: string;
  token: string;
}): Promise<{
  auth_token: string;
  client: string;
  uid: string;
  id: string;
  expiry: string;
  token_type: string;
}> {
  const response = await fetch(`${BASE_URL}/password`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeaders(),
    },
    body: JSON.stringify({
      password,
      password_confirmation: password,
      reset_password_token: token,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to update password");
  }

  return response.json();
}

export async function logUserOut() {
  return fetch(import.meta.env.VITE_REACT_APP_API_URL + "/auth/sign_out", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeaders(),
    },
    body: JSON.stringify({
      ...authHeaders(),
    }),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
}

export async function logAdminUserOut() {
  return fetch(
    import.meta.env.VITE_REACT_APP_API_URL + "/admin_auth/sign_out",
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        ...authHeaders(),
      },
      body: JSON.stringify({
        ...authHeaders(),
      }),
    },
  );
}

export async function getCurrentUser(): Promise<{
  success: boolean;
  data: GraphqlUser;
}> {
  const response = await fetch(
    import.meta.env.VITE_REACT_APP_API_URL + "/auth/validate_token",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...authHeaders(),
      },
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch user data");
  }

  return response.json();
}

export function getCurrentAdminUser() {
  return fetch(
    import.meta.env.VITE_REACT_APP_API_URL + "/admin_auth/validate_token",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...authHeaders(),
      },
    },
  );
}
