import BackgroundScreen from "@/components/BackgroundScreen";
import Spinner from "@/components/Spinner";
import { useAdminAuth } from "@/contexts/authentication/AdminAuthContext";
import { Navigate } from "react-router-dom";

export const AdminAuthenticatedRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { currentAdminUser, adminLoading } = useAdminAuth();

  if (!adminLoading && !currentAdminUser) {
    // User is not authenticated
    return <Navigate to="/admin/login" />;
  }

  if (adminLoading) {
    return (
      <BackgroundScreen>
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div className="h-[120px]">
              <div className="pt-10" />
              <Spinner size="medium" className="text-white" />
            </div>
          </div>
        </div>
      </BackgroundScreen>
    );
  }

  return <>{children}</>;
};
