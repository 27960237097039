// src/contexts/AdminAuthContext.tsx

import { useToast } from "@/hooks/use-toast";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@tanstack/react-query";
import React, { createContext, useCallback, useContext, useState } from "react";
import { logAdminUserOut as logAdminUserOutApi } from "../../api/auth.api";
import { clearAllTokens } from "../../api/utils";
import { GraphqlUser } from "../../pages/admin/types";
import {
  removeLocalStorageEntry,
  setLocalStorageEntry,
} from "../../utils/local-storage";
import { GET_CURRENT_ADMIN_USER_QUERY } from "./queries";

export const ADMIN_USER_LOCALSTORAGE_KEY = "_transcription_admin_user";

interface AdminAuthContextType {
  currentAdminUser: GraphqlUser | null;
  logAdminUserIn: (user: GraphqlUser) => void;
  logAdminUserOut: () => Promise<void>;
  adminLoading: boolean;
}

const AdminAuthContext = createContext<AdminAuthContextType | undefined>(
  undefined,
);

export const AdminAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { toast } = useToast();
  const [currentAdminUser, setCurrentAdminUser] = useState<GraphqlUser | null>(
    null,
  );

  const { loading: adminLoading } = useQuery(GET_CURRENT_ADMIN_USER_QUERY, {
    onCompleted: (data) => {
      setCurrentAdminUser(data?.currentAdminUser);
    },
  });

  const logAdminUserIn = useCallback((user: GraphqlUser) => {
    setLocalStorageEntry(ADMIN_USER_LOCALSTORAGE_KEY, {
      authToken: user.authToken,
      id: user.id,
    });
    // Ensure doctor user is logged out
    removeLocalStorageEntry("_transcription_doctor_user");
    setCurrentAdminUser(user);
  }, []);

  const logoutAdminUserMutation = useMutation({
    mutationFn: async () => {
      try {
        await logAdminUserOutApi();
      } catch (error) {
        console.log(error);
      } finally {
        toast({
          title: "Logged out",
          variant: "default",
        });
        setCurrentAdminUser(null);
        clearAllTokens();
        removeLocalStorageEntry(ADMIN_USER_LOCALSTORAGE_KEY);
      }
    },
  });

  const logAdminUserOut = () => logoutAdminUserMutation.mutateAsync();

  return (
    <AdminAuthContext.Provider
      value={{
        currentAdminUser,
        logAdminUserIn,
        logAdminUserOut,
        adminLoading,
      }}
    >
      {children}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = () => {
  const context = useContext(AdminAuthContext);
  if (context === undefined) {
    throw new Error("AdminAuthProvider is missing");
  }
  return context;
};
