import { useState } from "react";
import NewUserModal from "./NewUserDialog/NewUserDialog";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { RestAccount, RestUser } from "../../types";
import { isEmpty } from "lodash";
import PencilIcon from "@heroicons/react/16/solid/PencilIcon";
import EditUserDialog from "./EditUserDialog";
import Clickable from "@/components/Clickable";

const Row = ({
  user,
  refetch,
  account,
}: {
  user: RestUser;
  refetch: () => void;
  account: RestAccount;
}) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  return (
    <tr key={user.id} onClick={(e) => e.preventDefault()}>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{user.first_name}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{user.last_name}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{user.email}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{user.phone}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900 capitalize">
              {user.hospital_count}
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900 capitalize">
              {user.kind}
            </div>
          </div>
        </div>
      </td>
      <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
        <DialogTrigger>
          <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
            <div className="flex items-center">
              <Clickable>
                <PencilIcon className="h-5 w-5 text-gray-500" />
              </Clickable>
            </div>
          </td>
          <EditUserDialog
            user={user}
            refetch={refetch}
            account={account}
            handleClose={() => setIsEditDialogOpen(false)}
          />
        </DialogTrigger>
      </Dialog>
    </tr>
  );
};

const UsersTab = ({
  account,
  refetch,
}: {
  account: RestAccount;
  refetch: () => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className="inline-block min-w-full py-2 align-middle">
        <div className="flex flex-row-reverse">
          <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
            <DialogTrigger>
              <button className="rounded-md px-4 py-2 bg-blue-500 text-white font-medium">
                Add User
              </button>
              <NewUserModal
                refetch={refetch}
                account={account}
                handleClose={() => setIsModalOpen(false)}
              />
            </DialogTrigger>
          </Dialog>
        </div>
        {isEmpty(account.users) ? (
          <div className="text-center text-gray-500">No users yet</div>
        ) : (
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  First Name
                </th>
                <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Last Name
                </th>
                <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Email
                </th>
                <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Phone
                </th>
                <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Hospitals
                </th>
                <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Type
                </th>
                <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Edit
                </th>
              </tr>
            </thead>
            <tbody
              className="divide-y divide-gray-200 bg-white"
              onClick={(e) => e.stopPropagation()}
            >
              {account.users.map((user) => (
                <Row
                  key={user.id}
                  user={user}
                  refetch={refetch}
                  account={account}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default UsersTab;
