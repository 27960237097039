import { Input } from "@/components/ui/input";
import {
  RestAccount,
  GraphqlUser,
  UserKind,
  RestUser,
} from "@/pages/admin/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { default as SelectReact } from "react-select";
import { z } from "zod";

import { UserApi } from "@/api/user.api";
import Spinner from "@/components/Spinner";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectGroup,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/hooks/use-toast";

import { useMutation } from "@tanstack/react-query";

// Zod schema for form validation
const editUserSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  postNominal: z.string().optional(),
  email: z.string().email("Invalid email format"),
  phone: z.string().min(1, "Phone number is required"),
  kind: z.nativeEnum(UserKind),
  hospitalIds: z
    .array(z.string())
    .min(1, "Please select at least one hospital"),
  analyticsAccess: z.boolean(),
});

type EditUserFormData = z.infer<typeof editUserSchema>;

const EditUserDialog = ({
  account,
  user,
  handleClose,
  refetch,
}: {
  refetch: () => void;
  account: RestAccount;
  user: RestUser;
  handleClose: () => void;
}) => {
  console.log(user);
  const form = useForm<EditUserFormData>({
    resolver: zodResolver(editUserSchema),
    defaultValues: {
      ...user,
      // @ts-expect-error - We don't yet return analytics access
      analyticsAccess: !!user?.analyticsAccess,
      hospitalIds: user?.hospitals.map(({ id }) => id),
    },
  });

  const { errors } = form.formState;
  const { toast } = useToast();

  const mutation = useMutation({
    mutationFn: (data: EditUserFormData) =>
      UserApi.updateUser({
        id: user.id,
        data: {
          user: {
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            phone: data.phone,
            kind: data.kind,
            hospital_ids: data.hospitalIds,
            has_analytics: data.analyticsAccess,
          },
        },
      }),
    onSuccess: () => {
      toast({
        title: "User updated successfully",
      });
    },
    onSettled: () => {
      refetch();
      handleClose();
    },
  });

  const onSubmit = (data: EditUserFormData) => {
    console.log(data);
    return mutation.mutate(data);
  };

  const hospitalOptions = account.hospitals.map((hospital) => ({
    value: hospital.id,
    label: hospital.name,
  }));

  return (
    <DialogContent
      onClick={(e) => e.stopPropagation()}
      className="max-h-[800px] flex flex-col"
      aria-description="Edit user dialog"
    >
      <DialogHeader>
        <DialogTitle>Edit User</DialogTitle>
      </DialogHeader>
      <div className="flex-1 overflow-y-scroll">
        <Form {...form}>
          <form
            className="flex flex-col max-w-full m-2 space-y-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              name="firstName"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex flex-col items-start">
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Jane" />
                  </FormControl>
                  <FormMessage>{errors.firstName?.message}</FormMessage>
                </FormItem>
              )}
            />

            <FormField
              name="lastName"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex flex-col items-start">
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Doe" />
                  </FormControl>
                  <FormMessage>{errors.lastName?.message}</FormMessage>
                </FormItem>
              )}
            />

            <FormField
              name="email"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex flex-col items-start">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="doctor@hospital.com" />
                  </FormControl>
                  <FormMessage>{errors.email?.message}</FormMessage>
                </FormItem>
              )}
            />

            <FormField
              name="phone"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex flex-col items-start">
                  <FormLabel>Phone</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="503-380-5300" />
                  </FormControl>
                  <FormMessage>{errors.phone?.message}</FormMessage>
                </FormItem>
              )}
            />

            <FormField
              name="kind"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex flex-col items-start">
                  <FormLabel>Type</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Role" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>Role</SelectLabel>
                          <SelectItem value={UserKind.Manager}>
                            Manager
                          </SelectItem>
                          <SelectItem value={UserKind.Doctor}>
                            Doctor
                          </SelectItem>
                          <SelectItem value={UserKind.Nurse}>Nurse</SelectItem>
                          <SelectItem value={UserKind.Other}>Other</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage>{errors.kind?.message}</FormMessage>
                </FormItem>
              )}
            />

            <FormField
              name="hospitalIds"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex flex-col items-start">
                  <FormLabel>Hospital</FormLabel>
                  <FormControl>
                    <SelectReact
                      className="w-full"
                      isMulti
                      options={hospitalOptions}
                      value={hospitalOptions.filter((option) =>
                        field?.value?.includes(option.value),
                      )}
                      onChange={(selected) =>
                        field.onChange(selected.map((option) => option.value))
                      }
                    />
                  </FormControl>
                  <FormMessage>{errors.hospitalIds?.message}</FormMessage>
                </FormItem>
              )}
            />

            <FormField
              name="analyticsAccess"
              control={form.control}
              render={({ field }) => (
                <FormItem className="w-full flex items-start gap-2">
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                  <FormLabel>Has Analytics Access?</FormLabel>
                </FormItem>
              )}
            />

            <div className="mt-6 flex justify-between">
              <Button
                type="submit"
                disabled={mutation.isPending}
                className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
              >
                {mutation.isPending ? <Spinner /> : "Save Changes"}
              </Button>
              <Button
                type="button"
                onClick={handleClose}
                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
              >
                Cancel
              </Button>
            </div>
            {/* Display error */}
            {mutation.error && (
              <div className="text-red-500 text-sm">
                {mutation.error.message}
              </div>
            )}
          </form>
        </Form>
      </div>
    </DialogContent>
  );
};

export default EditUserDialog;
